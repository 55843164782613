<template lang="pug">
  .candidate-hold
    v-dialog(v-model="showing" :max-width="500" no-click-animation origin)
      template(v-slot:activator="{ on }")
        v-btn(
          small
          outlined
          v-on="on"
          color="primary"
          :disabled="isDisabled"
        ) Hold
      v-card(data-test="label-modal")
        v-card-title Candidates hold options
        v-card-text
            p Selected candidates will changed hold status according to action

        v-card-actions
          v-btn(color="primary" text @click="toggle") Close
          v-spacer

          v-btn(color="success" text :loading="processing" @click="remove") Remove
          v-btn(color="warning" text :loading="processing" @click="putOnHold") Put on Hold
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'

export default {
  props: {
    activeItems: {
      type: Array,
      required: true
    }
  },

  inject: ['svc'],

  mixins: [errorsMixin],

  data: () => ({
    showing: false,
    processing: false
  }),

  computed: {
    isDisabled() {
      return !this.activeItems.length
    }
  },

  methods: {
    toggle() {
      this.showing = !this.showing
    },

    putOnHold() {
      this.send(true)
    },

    remove() {
      this.send(false)
    },

    async send(status) {
      try {
        this.processing = true
        let ids = this.activeItems.map(item => item.ID)
        await this.svc().changeOnHold(ids, status)
        this.$emit('change', status)
        this.toggle()
      } catch (error) {
        this.processError(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM
        })
      }
      finally {
        this.processing = false
      }
    }
  }
}
</script>
